import { bool, func, node } from 'prop-types'
import { useState } from 'react'
import A11yNavigation from '@/components/Primitive/A11yNavigation'
import Footer from '@/components/Common/Footer'
import Navigation from '@/components/Common/Navigation'
import dynamic from 'next/dynamic'

import styles from './Layout.module.scss'
import { useUserStore } from '@/userStore'

const CookieBanner = dynamic(() => import('@/components/Common/CookieBanner'), {
  ssr: false
})

const MainLayout = ({ children, hideNav, transparentNav, hideHeader }) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false)
  const { cookieNoticeDismissed, dismissCookieNotice } = useUserStore()
  return (
    <>
      {!hideNav && (
        <>
          <A11yNavigation>
            <a href="#content">Jump to main content</a>
            <a href="#navigation">Jump to primary navigation</a>
          </A11yNavigation>
          <Navigation
            onHideNav={() => setMobileNavOpen(false)}
            onShowNav={() => setMobileNavOpen(true)}
            showNav={isMobileNavOpen}
            center
            hideHeader={hideHeader}
            transparent={transparentNav}
            id="navigation"
          />
        </>
      )}
      <div className={styles.Content}>{children}</div>
      <Footer />
      {!cookieNoticeDismissed && (
        <CookieBanner onDismiss={dismissCookieNotice} />
      )}
    </>
  )
}

MainLayout.propTypes = {
  children: node,
  onHideNav: func,
  onShowNav: func,
  showNav: bool,
  hideNav: bool
}

export default MainLayout
