import Container from '@/components/Primitive/Container'
import Icon from '@/components/Primitive/Icon'
import React from 'react'
import SmartLink from '@/components/Primitive/SmartLink'
import Type from '@/components/Primitive/Type'

import config from '../../../../config'

import styles from './Footer.module.scss'

const links = config.navLinks.footerNav
const social = config.social

const Footer = () => {
  return (
    <footer className={styles.Footer}>
      <Container gutter center size="wide" className={styles.Container}>
        <div className={styles.SocialWrapper}>
          <Type as="h2" size="baseMedium" bold>
            &quot;Enemy of Boredom Clubs.&quot;
          </Type>
          <div className={styles.Social}>
            <SmartLink href={social.facebook} target="_blank">
              <Icon
                a11yText="Facebook"
                type="footer-facebook"
                width={35}
                height={35}
              />
            </SmartLink>
            <SmartLink href={social.instagram} target="_blank">
              <Icon
                a11yText="Instagram"
                type="footer-instagram"
                width={35}
                height={35}
              />
            </SmartLink>
          </div>
        </div>
        <div className={styles.LinkWrapper}>
          <Type size="small" className={styles.Copyright}>
            © {new Date().getFullYear()} &quot;Enemy of Boredom Clubs.&quot;
          </Type>
          <div className={styles.FooterLinks}>
            {links.map(({ label, to }) => (
              <SmartLink
                key={`Footer-link-${label}`}
                to={to}
                className={styles.FooterLink}
              >
                {label}
              </SmartLink>
            ))}
          </div>
        </div>
      </Container>
    </footer>
  )
}
export default Footer
