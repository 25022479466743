import { node, bool, string } from 'prop-types'
import classNames from 'classnames'
import SmartLink from '@/components/Primitive/SmartLink'
import Type from '@/components/Primitive/Type'

import styles from './NavigationLink.module.scss'

const NavigationLink = ({
  children,
  to,
  className,
  highlight,
  outline,
  secondary,
  isActive,
  category,
  ...other
}) => (
  <SmartLink
    className={classNames(
      styles.NavigationLink,
      highlight && styles.highlight,
      outline && styles.outline,
      isActive && styles.active,
      secondary && styles.secondary,
      className
    )}
    to={to}
    category={category}
    {...other}
  >
    <Type className={styles.NavLinkInner} as="span" size="menu">
      {children}
    </Type>
  </SmartLink>
)

NavigationLink.propTypes = {
  children: node,
  to: string,
  className: string,
  category: string,
  highlight: bool
}

export default NavigationLink
