import Icon from '@/components/Primitive/Icon'
import Image from '@/components/Primitive/Image'
import Overlay from '@/components/Primitive/Overlay'
import SmartLink from '@/components/Primitive/SmartLink'

import config from '../../../../../config'
import NavigationLink from '../NavigationLink'
import SubNavigation from '../SubNavigation'

import styles from './MobileNavigation.module.scss'

const { mainNav } = config.navLinks

const MobileNavigation = ({ open, onClose, userLinks }) => {
  const handleClose = (e) => {
    onClose(e)
  }

  return (
    <Overlay
      open={open}
      onClose={handleClose}
      className={styles.Overlay}
      overlayClassName={styles.Wrapper}
      closeOnOutsideClick
    >
      <div className={styles.MobileNavigation}>
        <div className={styles.Branding}>
          <SmartLink to="/">
            <Image
              image="/logo-irl-eob.png"
              className={styles.Logo}
              alt={'In Real Life Centre'}
              width={280}
              height={60}
            />
          </SmartLink>
        </div>
        <nav className={styles.Nav}>
          <div className={styles.NavLinks}>
            {userLinks.map(({ to, icon, label }) => (
              <NavigationLink key={`MobileNav-${to}`} to={to}>
                {icon && (
                  <Icon
                    type={icon}
                    className={styles.NavigationIcon}
                    width={24}
                    height={24}
                    a11yText={icon}
                  />
                )}
                {label}
              </NavigationLink>
            ))}
          </div>
          <div className={styles.NavLinks}>
            {[...mainNav].map(({ to, label, links }) =>
              links ? (
                <SubNavigation
                  key={`MobileSubNav-${label}`}
                  label={label}
                  links={links}
                  mobile
                />
              ) : (
                <NavigationLink
                  key={`MobileNav-${label}`}
                  className={styles.NavigationLink}
                  to={to}
                >
                  {label}
                </NavigationLink>
              )
            )}
          </div>
        </nav>
      </div>
    </Overlay>
  )
}

export default MobileNavigation
