import { useRef, useState } from 'react'
import classnames from 'classnames'
import Icon from '@/components/Primitive/Icon'
import useEscapeKey from '@/hooks/useEscapeKey'
import useOutsideClick from '@/hooks/useOutsideClick'

import NavigationLink from '../NavigationLink'

import styles from './SubNavigation.module.scss'

const SubNavigation = ({
  label,
  mobile,
  className,
  listClassName,
  highlight,
  links,
  isActive,
  icon
}) => {
  const [open, setOpen] = useState(false)
  const ref = useRef()

  const handleClose = () => {
    open && setOpen(false)
  }

  useEscapeKey(handleClose)
  useOutsideClick(ref, handleClose)

  return (
    <div
      ref={ref}
      className={classnames(
        styles.SubNavigation,
        highlight && styles.highlight,
        isActive && styles.active,
        className
      )}
    >
      <NavigationLink
        onClick={() => setOpen(!open)}
        className={styles.SubNavigationButton}
        isActive={isActive}
      >
        {icon}
        {label}
      </NavigationLink>
      <div
        className={classnames(
          styles.SubNavigationLinks,
          listClassName,
          open && styles.open
        )}
      >
        {links?.map((link) => (
          <NavigationLink
            key={`Subnav${mobile ? 'Mobile' : 'Desktop'}:${link.to}`}
            className={styles.SubNavigationLink}
            category={link.category}
            to={link.to}
          >
            {link.icon && (
              <Icon
                type={link.icon}
                className={styles.SubNavigationIcon}
                a11yText={link.icon}
              />
            )}
            {link.label}
          </NavigationLink>
        ))}
      </div>
    </div>
  )
}
export default SubNavigation
