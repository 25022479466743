import { bool, func, node, string } from 'prop-types'
import { createPortal } from 'react-dom'
import { GrClose } from 'react-icons/gr'
import { useRef } from 'react'
import classNames from 'classnames'
import useOutsideClick from '@/hooks/useOutsideClick'

import styles from './Overlay.module.scss'

const Overlay = ({
  children,
  open,
  title,
  onClose,
  smallScreenOnly,
  href,
  overlayClassName,
  closeOnOutsideClick,
  className
}) => {
  const LinkEl = href ? 'a' : 'div'
  const ref = useRef(null)
  const handleClose = () => {
    closeOnOutsideClick && onClose()
  }
  useOutsideClick(ref, handleClose)

  if (!open) return null
  return createPortal(
    <div
      className={classNames(
        styles.OverlayWrapper,

        smallScreenOnly && styles.smallScreenOnly
      )}
    >
      <div ref={ref} className={classNames(styles.Overlay, overlayClassName)}>
        <div className={styles.OverlayHeader}>
          {title && (
            <LinkEl className={styles.OverlayTitle} href={href}>
              {title}
            </LinkEl>
          )}
          {onClose && (
            <button
              type="button"
              className={styles.OverlayClose}
              onClick={onClose}
            >
              <GrClose size="1em" />
            </button>
          )}
        </div>

        <div className={className}>{children}</div>
      </div>
    </div>,
    document.body
  )
}

Overlay.defaultProps = {}

Overlay.propTypes = {
  open: bool,
  onClose: func,
  children: node,
  title: string,
  smallScreenOnly: bool,
  className: string
}

export default Overlay
