import { bool, node, string } from 'prop-types'
import classNames from 'classnames'
import React from 'react'
import SmartLink from '@/components/Primitive/SmartLink'

import styles from './IconButton.module.scss'

/**
 * A simple button component used to present clickable Icons
 */
const IconButton = ({
  children,
  className,
  icon,
  increaseHitArea,
  rounded,
  small,
  solid,
  primary = true,
  secondary,
  ...other
}) => (
  <SmartLink
    className={classNames(
      styles.IconButton,
      increaseHitArea && styles.increaseHitArea,
      rounded && styles.rounded,
      small && styles.small,
      solid && styles.solid,
      primary && styles.primary,
      secondary && styles.secondary,
      children && styles.withChildren,
      className
    )}
    {...other}
  >
    <div className={styles.IconButtonInner}>
      {icon}
      {children && <span className={styles.IconButtonText}>{children}</span>}
    </div>
  </SmartLink>
)

IconButton.propTypes = {
  children: node,
  className: string,
  icon: node.isRequired,
  increaseHitArea: bool,
  rounded: bool,
  small: bool,
  solid: bool,
  primary: bool
}

export default IconButton
