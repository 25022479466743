import { func, bool, string } from 'prop-types'
import { useInView } from 'react-intersection-observer'
import classnames from 'classnames'
import React from 'react'

import styles from './Navigation.module.scss'

import { FaPhone, FaPlus, FaUserCircle } from 'react-icons/fa'
import { HiMenuAlt4 } from 'react-icons/hi'
import { MdClose } from 'react-icons/md'
import SmartLink from '@/components/Primitive/SmartLink'
import Image from '@/components/Primitive/Image'
import Container from '@/components/Primitive/Container'
import IconButton from '@/components/Primitive/IconButton'
import MobileNavigation from './MobileNavigation'
import SubNavigationComp from './SubNavigation'
import NavigationLink from './NavigationLink'
import config from '../../../../config'
import { filterNavLinks } from '@/lib/helpers'
import { useUserStore } from '@/userStore'
import { useRouter } from 'next/router'
import useHasMountedLayout from '@/hooks/useHasMountedLayout'
import { IoIosMail } from 'react-icons/io'
import { BsFillCompassFill } from 'react-icons/bs'
const { mainNav, userNav } = config.navLinks

const getBasePath = (path) => `/${path?.split('/')?.[1]}`

const SubNavigation = React.memo(SubNavigationComp)
const Navigation = ({
  onHideNav,
  onShowNav,
  showNav,
  id,
  hideHeader,
  hideBranding,
  center = false,
  fullWidth
}) => {
  const router = useRouter()
  const hasMounted = useHasMountedLayout()
  const { loggedIn, fullName, isAdmin } = useUserStore()
  const { ref, inView } = useInView({
    threshold: 0.3,
    initialInView: true,
    skip: hideHeader
  })
  const isFixed = !hideHeader && inView
  const isAuthed = hasMounted && loggedIn
  const filteredUserLinks = filterNavLinks(userNav, isAuthed, isAdmin)
  return (
    <>
      <MobileNavigation
        userLinks={filteredUserLinks}
        open={showNav}
        onClose={onHideNav}
      />
      {!hideHeader && (
        <div className={classnames(styles.HeaderWrapper)}>
          <Container
            className={classnames(styles.Header)}
            as="nav"
            size={!fullWidth && 'wide'}
            center={center}
          >
            <div className={styles.HeaderBranding}>
              <SmartLink to="/" as="/">
                <Image
                  image={'/logo.png'}
                  className={styles.Logo}
                  alt={'In Real Life Centre'}
                  width={300}
                  height={80}
                />
              </SmartLink>
            </div>
            <div className={styles.HeaderLinks}>
              <SmartLink href="tel:01344304978" className={styles.HeaderItem}>
                <FaPhone size="18px" />
                <span ref={ref}>
                  <strong>Call us</strong>
                  <div className={styles.HeaderDetail}>01344304978</div>
                </span>
              </SmartLink>

              <SmartLink
                href="mailto:info@eobacademy.com"
                className={styles.HeaderItem}
              >
                <IoIosMail size="18px" />
                <span>
                  <strong>Email</strong>
                  <div className={styles.HeaderDetail}>info@eobacademy.com</div>
                </span>
              </SmartLink>
              <SmartLink href="/#location" className={styles.HeaderItem}>
                <BsFillCompassFill size="18px" />
                <span>
                  <strong>Location</strong>
                  <div className={styles.HeaderDetail}>
                    Letchworth, Bracknell and Online
                  </div>
                </span>
              </SmartLink>
            </div>
          </Container>
        </div>
      )}
      <div
        className={classnames(
          styles.Navigation,
          hideBranding && styles.hideBranding,
          isFixed && styles.fixed
        )}
      >
        <Container
          as="nav"
          size={!fullWidth && 'wide'}
          center={center}
          className={classnames(
            styles.NavigationInner,
            showNav && styles.showNav
          )}
          id={id}
        >
          <div className={styles.Branding}>
            <SmartLink to="/">
              <Image
                image={'/logo-small.png'}
                className={styles.Logo}
                alt={'Eob Clubs'}
                width={40}
                height={32}
              />
            </SmartLink>
          </div>

          <div className={styles.MainNav}>
            {mainNav &&
              mainNav.map(({ to, label, links }) =>
                links ? (
                  <SubNavigation
                    isActive={
                      getBasePath(router?.asPath) == `/${label.toLowerCase()}`
                    }
                    key={`DesktopSubNav-${label}`}
                    label={label}
                    highlight
                    links={links}
                  />
                ) : (
                  <NavigationLink
                    isActive={router?.asPath === to}
                    key={`DesktopNav-${label}`}
                    to={to}
                  >
                    {label}
                  </NavigationLink>
                )
              )}
          </div>
          <NavigationLink
            className={styles.Bookings}
            secondary
            to={`/booking/2`}
            title="Make a booking"
          >
            <FaPlus size="1em" />
            &nbsp;Booking
          </NavigationLink>
          <div className={styles.UserNav}>
            {!isAuthed && (
              <div className={styles.UserNav}>
                <IconButton
                  to={'/log-in'}
                  icon={
                    <FaUserCircle className={styles.UserIcon} size="1.7em" />
                  }
                  className={styles.UserButton}
                  small
                >
                  Account
                </IconButton>
              </div>
            )}
            {isAuthed && (
              <SubNavigation
                label={fullName && fullName.split(' ')[0]}
                links={filteredUserLinks}
                listClassName={styles.UserNavList}
                icon={<FaUserCircle className={styles.UserIcon} size="1.7em" />}
              />
            )}
          </div>
          <button
            className={styles.ToggleNavButton}
            onClick={showNav ? onHideNav : onShowNav}
            onTouchStart={showNav ? onHideNav : onShowNav}
          >
            {showNav ? <MdClose size="1.3em" /> : <HiMenuAlt4 size="1.3em" />}
          </button>
        </Container>
      </div>
    </>
  )
}

Navigation.propTypes = {
  onHideNav: func,
  onShowNav: func,
  showNav: bool,
  hideHeader: bool,
  hideBranding: bool,
  transparent: bool,
  id: string,
  fullWidth: bool,
  center: bool
}

export default Navigation
