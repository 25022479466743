import add from 'date-fns/add'
import config from '../../config'
const { eventTypes } = config

// Is this an event booking?
export const isEventBookingType = (bookingType) =>
  eventTypes.map((type) => type.type).includes(bookingType)

const defaultType = 'holidayClubs'
export const createRandomEvent = (type = defaultType) => {
  const hipsum = require('lorem-hipsum')
  const title = hipsum({
    count: 1,
    units: 'sentences',
    sentenceLowerBound: 2, // Minimum words per sentence.
    sentenceUpperBound: 5
  })
  const description = hipsum({
    count: 1,
    units: 'sentences',
    sentenceLowerBound: 5, // Minimum words per sentence.
    sentenceUpperBound: 15
  })
  const startDate = add(new Date(), {
    days: Math.floor(Math.random() * 100)
  })
  const availableSpots = Math.floor(Math.random() * 30)
  const event = {
    eventType: type,
    eventName: title,
    description: description,
    price: Math.floor(Math.random() * 100),
    url: 'https://www.irlletchworth.com/events',
    maxSpots: availableSpots,
    date: [
      startDate,
      add(startDate, { days: Math.floor(Math.random() * 100) })
    ],
    imageUrl: `https://picsum.photos/seed/${Math.floor(
      Math.random() * 10000
    )}/600/300`,
    slotFrom: '09:00',
    slotTo: '18:00'
  }

  return event
}

export const formatEventBooking = (eventBooking) => {
  return {
    ...eventBooking.event,
    ...eventBooking,
    _id: eventBooking._id,
    eventId: eventBooking.event._id,
    ...(eventBooking?.user && {
      user: eventBooking.user
    })
  }
}

// Filters navigation links
export const filterNavLinks = (links, isAuthed, isAdmin) =>
  links.filter((link) => {
    if (link.isAdmin && isAuthed && isAdmin) {
      return true
    } else if (link.isAuthed && !link.isAdmin && isAuthed) {
      return true
    } else if (!link.isAuthed && !isAuthed) {
      return true
    } else return false
  })
